import React from 'react'
import CreateCustomContent from './createCustomContent'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function CustomContentItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateCustomContent {...props} id={id} />
}

export default CustomContentItem;